import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SEO from 'components/seo'
import SocialLinks from 'components/socialLinks'
import CustomPageSectionComponent from 'components/customPageSectionComponent'
import ContactFormButton from 'components/contactFormButton.js'
import { jsonId } from 'utils'
import HelmetExport from 'react-helmet'
import {
  pageWrapper,
  noFadeHeadingWrapper,
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Infrastructure Modernisation" }) {
        edges {
          node {
            professionalChildrenFieldGroup {
              childPageHeader
              childPageHeaderImage {
                sourceUrl
              }
              childPageSubtext
              childPageCtaText
              childPageCtaLink
              whatIsSectionDescription
              whatIsSectionHeader
              whatIsSectionSubheader
              howWeCanHelpHeader
              howWeCanHelpSubheader
              howWeCanHelpContent
              ourApproachSectionDescription
              ourApproachSectionHeader
              ourApproachSectionSubheader
              whyDeimosSectionDescription
              whyDeimosSectionHeader
              whyDeimosSectionSubheader
            }
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const InfrastructureModernisation = ({ data, pageContext }) => {
  const childPageData = data.wordPress.pages.edges[0].node.professionalChildrenFieldGroup
  const childPageHeaderImage = childPageData.childPageHeaderImage.sourceUrl
  const whatIsSectionContent = childPageData.whatIsSectionDescription
  const whatIsSectionHeader = childPageData.whatIsSectionHeader
  const whatIsSectionSubheader = childPageData.whatIsSectionSubheader
  const howWeCanHelpSectionContent = childPageData.howWeCanHelpContent
  const howWeCanHelpSectionHeader = childPageData.howWeCanHelpHeader
  const howWeCanHelpSectionSubheader = childPageData.howWeCanHelpSubheader
  const ourApproachSectionDescription = childPageData.ourApproachSectionDescription
  const ourApproachSectionHeader = childPageData.ourApproachSectionHeader
  const ourApproachSectionSubheader = childPageData.ourApproachSectionSubheader
  const whyDeimosSectionContent = childPageData.whyDeimosSectionDescription
  const whyDeimosSectionHeader = childPageData.whyDeimosSectionHeader
  const whyDeimosSectionSubheader = childPageData.whyDeimosSectionSubheader
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/professional-services`,
            'name': 'Professional Services',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/professional-services/platform-modernisation`,
            'name': 'Platform Modernisation',
            'position': 3
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 4
          }]
        })}
      </HelmetExport>
      <SEO
        description='Our Infrastructure Modernisation Information'
        title='Infrastructure Modernisation'
      />
      <div className={pageWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Infrastructure Modernisation'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
        </div>
        <div
          className={noFadeHeadingWrapper}
          style={{ backgroundImage: `url(${childPageHeaderImage})` }}>
          <h1>{childPageData.childPageHeader}</h1>
          <p>{childPageData.childPageSubtext}</p>
          <ContactFormButton ctaText={childPageData.childPageCtaText} />
        </div>
        <CustomPageSectionComponent
          hasWhiteBackground
          isProfessionalServiceSubpage
          items={whatIsSectionContent}
          subtitle={whatIsSectionSubheader}
          title={whatIsSectionHeader}
        />
        <CustomPageSectionComponent
          isProfessionalServiceSubpage
          items={howWeCanHelpSectionContent}
          subtitle={howWeCanHelpSectionSubheader}
          title={howWeCanHelpSectionHeader}
        />
        <CustomPageSectionComponent
          hasWhiteBackground
          isProfessionalServiceSubpage
          items={ourApproachSectionDescription}
          subtitle={ourApproachSectionSubheader}
          title={ourApproachSectionHeader}
        />
        <CustomPageSectionComponent
          isProfessionalServiceSubpage
          items={whyDeimosSectionContent}
          subtitle={whyDeimosSectionSubheader}
          title={whyDeimosSectionHeader}
        />
        <SocialLinks
          hasWhiteBackground
          socialLinks={footerData.socialLinks} />
      </div>
    </>
  )
}

InfrastructureModernisation.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default InfrastructureModernisation
